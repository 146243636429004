import React from 'react'
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components'
import BaseGrid from '../components/base-grid';
import ImageFull from '../components/image-full';
import logoSvg from '../images/content/posters/napis.svg';
import { Link } from "gatsby"

export default function Posters({ data, pageContext: { items, filesPathRelative, slug } }) {
  const imagesByFileName = data.allFile.nodes.reduce((acc, n) => {
    if (!n.childImageSharp) return acc;
    return ({
      ...acc,
      [n.childImageSharp.fluid.originalName.replace('.png', '')]: n.childImageSharp.fluid,
    })
  }, {})

  return (
    <Layout fixedBottom={
      <ImageFull src={logoSvg} />
    }>
      <BaseGrid>
        {items.map(({ id }) =>
          <Link to={`${slug}/${id}`} style={{ display: 'block' }}>
            <Img
              key={id}
              fluid={imagesByFileName[id]}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Link>
        )}
      </BaseGrid>
    </Layout>
  )
}

export const query = graphql`
  query PostersImages($imagesGlob: String!){
    allFile(filter: {relativePath: {glob: $imagesGlob}}) {
      nodes {
        childImageSharp {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`